import React from 'react';

import {useAuth0, GetTokenSilentlyOptions} from '@auth0/auth0-react';

import {ITokenData, ILogoutOptions, ILoginOptions, authRedirectLocalStorageKey} from 'shared/auth/models';
import {SignupStatus} from 'modules/profile/models/signup-status';
import {usePermissionsLoadable} from 'shared/auth/hooks/usePermissionsLoadable';
import {getUUIDFromUser} from 'shared/auth/utils/get-uuid-from-token';
import {useWindowSize} from 'shared/hooks/useWindowSize';

interface IUseAuthProviderValue {
    tokenData: ITokenData | undefined;
    isLoading: boolean;
    isAuthenticated: boolean;
    getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
    signOut: (logoutOptions?: ILogoutOptions) => void;
    login: (loginOptions?: ILoginOptions) => Promise<void>;
}

interface IUseGuaranteedAuthValue {
    tokenData: ITokenData;
    getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
    signOut: (logoutOptions?: ILogoutOptions) => void;
    login: (loginOptions?: ILoginOptions) => Promise<void>;
}
const stub = (): never => {
    throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const initialAuthContext = {
    isLoading: true,
    isAuthenticated: false,
    tokenData: undefined,
    getAccessTokenSilently: stub,
    signOut: stub,
    login: stub,
    signupStatus: SignupStatus.Loading,
};

export const authContext = React.createContext<IUseAuthProviderValue>(initialAuthContext);
export const useAuth = () => {
    return React.useContext(authContext);
};

export const useGuaranteedAuth = (): IUseGuaranteedAuthValue => {
    const {tokenData, ...rest} = React.useContext(authContext);
    if (!tokenData) {
        throw new Error('not authenticated');
    }
    return {
        tokenData,
        ...rest,
    };
};

// Provider hook that creates auth object and handles state
export function useAuthProvider(): IUseAuthProviderValue {
    const {user, isAuthenticated, isLoading, logout, getAccessTokenSilently, loginWithRedirect} = useAuth0();
    const {permissions, scopes} = usePermissionsLoadable(isAuthenticated);

    const uuid = React.useMemo<string | undefined>(() => {
        if (user) {
            return getUUIDFromUser(user);
        }
    }, [user]);

    const signOut = (logoutOptions?: ILogoutOptions) => {
        return logout({
            returnTo: logoutOptions?.returnTo ?? window.location.origin,
        });
    };

    const {width} = useWindowSize();
    const login = async ({from, ...loginOptions}: ILoginOptions = {}) => {
        if (!from) {
            localStorage.setItem(
                authRedirectLocalStorageKey,
                window.location.pathname.startsWith('/login')
                    ? width < 450
                        ? '/'
                        : '/dashboard'
                    : window.location.pathname === '/'
                        ? width < 450
                            ? '/'
                            : '/dashboard'
                        : window.location.pathname + window.location.search
            );
        } else {
            localStorage.setItem(authRedirectLocalStorageKey, from);
        }
        return loginWithRedirect({redirectUri: window.location.origin, ...loginOptions});
    };

    const tokenData = React.useMemo<ITokenData | undefined>(() => {
        if (!user || !permissions || !scopes || !uuid) {
            return undefined;
        }
        return {
            id: uuid,
            permissions,
            scopes,
            email: user.email,
            emailVerified: user.email_verified,
        };
    }, [user, permissions, scopes, uuid]);

    // Return the user object and auth methods
    return {
        tokenData,
        isLoading,
        isAuthenticated,
        getAccessTokenSilently,
        signOut,
        login,
    };
}
