import React, {useEffect, useState} from 'react';

import 'react-loading-skeleton/dist/skeleton.css';
import {Outlet, Route, Routes, Navigate, Link, useLocation} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import classNames from 'classnames';

import {useSignupStatus} from 'modules/profile/hooks/useSignupStatus';
import {backgroundColorAtom, BackgroundColor} from 'shared/hooks/useBackgroundColor';

import {ScrollToTop} from 'shared/components/ScrollToTop/ScrollToTop';
import {AppNavbar} from 'shared/components/AppNavbar/AppNavbar';
import {HomePage} from 'public/HomePage/HomePage';
import {GameChanger} from 'public/GameChangerPage/GameChanger';
import {TermsPage} from 'public/TermsPage/TermsPage';
import {PrivacyPage} from 'public/PrivacyPage/PrivacyPage';
import {ContactPage} from 'public/ContactPage/ContactPage';
import {AboutUsPage} from 'public/AboutUsPage/AboutUsPage';
import {ViewProfilePage} from 'public/ViewProfilePage/ViewProfilePage';
import {PostView} from 'public/PostView/PostView';
import {NotFound} from 'public/NotFound/NotFound';
import {MyProfilePage} from 'user/pages/MyProfilePage/MyProfilePage';
import {AppNavbarLink} from 'shared/components/AppNavbar/AppNavbarLink';
import {FrequentlyAskedQuestions} from 'public/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import {ProfileMarketplace} from 'public/ProfileMarketplace/ProfileMarketplace';
import {UpdateUserPostPage} from 'user/pages/UpdateUserPostPage/UpdateUserPostPage';
import {ResourcesPage} from 'public/ResourcesPage/ResourcesPage';
import {CreateCaseEstimator} from 'user/pages/CreateCaseEstimator/CreateCaseEstimator';
import GuideToChoosingPage from 'public/GuideToChoosingPage/GuideToChoosingPage';
import {CoolLegalLinksPage} from 'public/CoolLegalLinksPage/CoolLegalLinksPage';
import {BecomeMember} from 'public/BecomeMember/BecomeMember';
import {PodcastView} from 'public/PodcastView/PodcastView';
import {HelpCenterPage} from 'public/help-center/HelpCenter/HelpCenterPage';
import {HelpCenterCategory} from 'public/help-center/HelpCenterCategory/HelpCenterCategory';
import {HelpCenterArticle} from 'public/help-center/HelpCenterArticle/HelpCenterArticle';
import {DevelopmentPipeline} from 'public/DevelopmentPipeline/DevelopmentPipeline';
import {RequireAuth} from 'auth/RequireAuth/RequireAuth';
import {RouteSpinner} from 'auth/RouteSpinner/RouteSpinner';
import {ProfileEdit} from 'user/pages/ProfileEdit/ProfileEdit';
import {LoginPage} from 'shared/auth/components/LoginPage';
import {LogoutPage} from 'shared/auth/components/LogoutPage';
import {SignupRedirect} from 'shared/auth/components/SignupRedirect';
import {RequireSignup} from 'auth/RequireSignup/RequireSignup';
import {TechProductsPage} from 'public/TechProductsPage/TechProductsPage';
import {StripeSignup} from 'public/StripeSignup/StripeSignup';
import {FileTokenRefresher} from 'shared/components/file/FileTokenRefresher';
import {Dropdown} from 'react-bootstrap';
import {DropdownCaret} from 'shared/components/DropdownCaret/DropdownCaret';
import {ProfileUser} from 'shared/icons/navbar';
import {ArrowRight, ResourceIcon, Slack, Suitcase} from 'shared/icons/dashboard';

const UserApp = React.lazy(() => import('user/UserApp'));
const AuthApp = React.lazy(() => import('auth/AuthApp'));
const JobList = React.lazy(() => import('public/JobList/JobList'));
const Signature = React.lazy(() => import('public/Signature/Signature'));

export const App = () => {
    const {profile} = useSignupStatus();
    const background = useRecoilValue(backgroundColorAtom);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {pathname} = useLocation();

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const className = React.useMemo(
        () =>
            classNames('App browser-height', {
                'bg-light': background === BackgroundColor.LightGray,
            }),
        [background]
    );

    return (
        <div className={className}>
            <ScrollToTop />
            <FileTokenRefresher />
            <Routes>
                <Route
                    path="/redirect/stripe-signup/:userId"
                    element={
                        <RequireAuth>
                            <StripeSignup />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/dashboard/*"
                    element={
                        <RequireAuth>
                            <UserApp />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/login/*"
                    element={
                        <RequireSignup>
                            <LoginPage />
                        </RequireSignup>
                    }
                />
                <Route
                    path="/signup/*"
                    element={
                        <RequireSignup>
                            <SignupRedirect />
                        </RequireSignup>
                    }
                />
                <Route path="/logout/*" element={<LogoutPage />} />
                <Route
                    element={
                        <>
                            <AppNavbar>
                                <AppNavbarLink isMobile={windowWidth < 993 && true} to="/contact">
                                    Contact
                                </AppNavbarLink>
                                <AppNavbarLink isMobile={windowWidth < 993 && true} to="/about">
                                    About
                                </AppNavbarLink>
                                {windowWidth < 993 && (
                                    <AppNavbarLink isMobile={windowWidth < 993 && true} to="/network">
                                        Network
                                    </AppNavbarLink>
                                )}
                                <Dropdown className="AppNavbar__network-dropdown">
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        Network
                                        <DropdownCaret className="text-white" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu
                                        popperConfig={{modifiers: [{name: 'offset', options: {offset: [0, 10]}}]}}
                                    >
                                        <div className="dropdown__triangle" />
                                        <div className="dropdown__navlinks">
                                            <Dropdown.Item
                                                to="/network"
                                                as={Link}
                                                className={pathname === '/network' ? 'active' : ''}
                                            >
                                                <div className="dropdown__header">
                                                    <ProfileUser />
                                                    <p className="mb-0">Professional Profiles</p>
                                                </div>
                                                <p className="dropdown__info mb-0">
                                                    Search profiles of legal professionals from around Australia.
                                                </p>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                to="/jobs"
                                                as={Link}
                                                className={pathname === '/jobs' ? 'active' : ''}
                                            >
                                                <div className="dropdown__header">
                                                    <Suitcase />
                                                    <p className="mb-0">Jobs</p>
                                                </div>
                                                <p className="dropdown__info mb-0">
                                                    Read, respond or post legal jobs to our network.
                                                </p>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                to="/resources"
                                                as={Link}
                                                className={pathname === '/resources' ? 'active' : ''}
                                            >
                                                <div className="dropdown__header">
                                                    <ResourceIcon />
                                                    <p className="mb-0">Resources</p>
                                                </div>
                                                <p className="dropdown__info mb-0">
                                                    Read the latest legal resources posted by our freelancers.
                                                </p>
                                            </Dropdown.Item>
                                        </div>
                                        <div className="dropdown__slack-community">
                                            <div className="slack__header">
                                                <Slack />
                                                <p className="mb-0">Connect with our legal community</p>
                                            </div>
                                            <p className="slack__description">
                                                Network with lawyers, keep up with trends, learn from experts.
                                            </p>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://join.slack.com/t/rightfulau/shared_invite/zt-2p7lv1wcz-1NbtVbGfMGtKa_uug_om0Q"
                                            >
                                                Join Our Slack Community
                                                <ArrowRight />
                                            </a>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </AppNavbar>
                            <Outlet />
                        </>
                    }
                >
                    <Route
                        path="/case-estimator/create"
                        element={
                            <RequireAuth>
                                <CreateCaseEstimator />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/auth/*"
                        element={
                            <RouteSpinner>
                                <AuthApp />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <RequireAuth>
                                <MyProfilePage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/profile/edit"
                        element={
                            <RequireAuth>
                                <ProfileEdit />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/posts/:postId/update"
                        element={
                            <RequireAuth>
                                <UpdateUserPostPage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/gamechanger"
                        element={
                            <RouteSpinner>
                                <GameChanger />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/help-center/:categoryId/:articleId"
                        element={
                            <RouteSpinner>
                                <HelpCenterArticle />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/help-center/:categoryId"
                        element={
                            <RouteSpinner>
                                <HelpCenterCategory />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/help-center"
                        element={
                            <RouteSpinner>
                                <HelpCenterPage />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/contact"
                        element={
                            <RouteSpinner>
                                <ContactPage />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/development-pipeline"
                        element={
                            <RouteSpinner>
                                <DevelopmentPipeline />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/jobs"
                        element={
                            <RouteSpinner>
                                <JobList />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/about"
                        element={
                            <RouteSpinner>
                                <AboutUsPage />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/guide-choose-legal-team"
                        element={
                            <RouteSpinner>
                                <GuideToChoosingPage />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/become-a-member"
                        element={
                            <RouteSpinner>
                                <BecomeMember />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/cool-legal-links-page"
                        element={
                            <RouteSpinner>
                                <CoolLegalLinksPage />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/terms"
                        element={
                            <RouteSpinner>
                                <TermsPage />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/privacy"
                        element={
                            <RouteSpinner>
                                <PrivacyPage />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/techproducts"
                        element={
                            <RouteSpinner>
                                <TechProductsPage />
                            </RouteSpinner>
                        }
                    />
                    <Route
                        path="/faq"
                        element={
                            <RouteSpinner>
                                <FrequentlyAskedQuestions />
                            </RouteSpinner>
                        }
                    />
                    <Route path="/marketplace" element={<Navigate to="/network" />} />
                    <Route
                        path="/network"
                        element={
                            <RequireSignup>
                                <RouteSpinner hideSpinner>
                                    <ProfileMarketplace />
                                </RouteSpinner>
                            </RequireSignup>
                        }
                    />
                    <Route
                        path="/profiles/:profileId"
                        element={
                            <RequireSignup>
                                <RouteSpinner>
                                    <ViewProfilePage />
                                </RouteSpinner>
                            </RequireSignup>
                        }
                    />
                    <Route
                        path="/posts/podcast/:postId"
                        element={
                            <RequireSignup>
                                <RouteSpinner>
                                    <PodcastView />
                                </RouteSpinner>
                            </RequireSignup>
                        }
                    />
                    <Route
                        path="/posts/:postId"
                        element={
                            <RequireSignup>
                                <RouteSpinner>
                                    <PostView />
                                </RouteSpinner>
                            </RequireSignup>
                        }
                    />
                    <Route
                        path="/resources/*"
                        element={
                            <RequireSignup>
                                <RouteSpinner>
                                    <ResourcesPage />
                                </RouteSpinner>
                            </RequireSignup>
                        }
                    />
                    <Route
                        path="/signature/:signatureRequestId/signers/:signerId"
                        element={
                            <RequireSignup>
                                <RouteSpinner>
                                    <Signature />
                                </RouteSpinner>
                            </RequireSignup>
                        }
                    />
                    <Route
                        path="/"
                        element={
                            <RequireSignup>
                                <HomePage />
                            </RequireSignup>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <RequireSignup>
                                <NotFound buttonUrl="/" buttonTitle="RETURN HOME" />
                            </RequireSignup>
                        }
                    />
                </Route>
            </Routes>
        </div>
    );
};
