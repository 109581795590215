import React from 'react';

import {Button, Col, Dropdown, Form, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {useRecoilValueLoadable} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {useCombobox} from 'downshift';

import {useWindowSize} from 'shared/hooks/useWindowSize';
import {useDebounce} from 'shared/hooks/useDebounce';
import {IProfile} from 'modules/profile/models/IProfile';
import {profileListReadSelector} from 'modules/profile/state/profile-list';
import {IProfileListQuery} from 'modules/profile/models/IProfileListQuery';
import {getFullName} from 'modules/profile/utils';
import {IProfilesPageFilters} from 'shared/state/profiles-page';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';

import './style.scss';

interface IProfilesPageSearchProps {
    showModal: () => void;
    setFilters: (filters: IProfilesPageFilters) => void;
    listFilters: IProfilesPageFilters;
    isSticky: boolean;
}

export const ProfilesPageSearch = ({showModal, setFilters, listFilters, isSticky}: IProfilesPageSearchProps) => {
    const navigate = useNavigate();
    const windowWidth = useWindowSize();

    const [hasSuccess, setHasSuccess] = React.useState<boolean>(false);
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const debouncedSearchTerm: string = useDebounce(searchTerm, 200);

    const filters = React.useMemo<IProfileListQuery>(
        () => ({
            limit: 10,
            isPublished: true,
            searchTerm,
        }),
        [searchTerm]
    );

    const profilesLoadable = useRecoilValueLoadable(
        profileListReadSelector({
            filters,
        })
    );

    const profiles: IProfile[] = profilesLoadable.valueMaybe()?.profiles ?? [];
    const isLoading =
        profilesLoadable.state === 'loading' || (!!searchTerm?.length && searchTerm !== debouncedSearchTerm);

    const onSelectedItem = (profile: IProfile) => {
        setSearchTerm('');
        navigate(`/profiles/${profile.id}`);
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setFilters({...listFilters, searchTerm});
    };

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
    } = useCombobox({
        inputValue: searchTerm,
        items: profiles,
        onSelectedItemChange: ({selectedItem}) => (selectedItem ? onSelectedItem(selectedItem) : undefined),
        isOpen: !debouncedSearchTerm.length ? false : undefined,
        itemToString: (profile) => (profile ? getFullName(profile) : ''),
    });

    // Prevent an empty dropdown from appearing by tracking whether we have had a successful response
    React.useEffect(() => {
        if (!hasSuccess && profilesLoadable.state === 'hasValue' && !!debouncedSearchTerm.length) {
            setHasSuccess(true);
        } else if (hasSuccess && profilesLoadable.state === 'hasError') {
            setHasSuccess(false);
        } else if (hasSuccess && !debouncedSearchTerm.length) {
            setHasSuccess(false);
        }
    }, [profilesLoadable, debouncedSearchTerm, hasSuccess, setHasSuccess]);

    return (
        <Row className="m-0">
            <Col md={{span: 8, offset: 2}}>
                <Form onSubmit={onSubmit}>
                    <div className="row mobileSearchBox m-0">
                        <div className="ProfilesPageSearch">
                            <FontAwesomeIcon className="ProfilesPageSearch__icon" icon={faSearch} />
                            <Form.Control
                                {...getInputProps({
                                    onChange: (e) => setSearchTerm((e.target as HTMLInputElement).value),
                                })}
                                {...getComboboxProps({
                                    className: 'ml-2 mr-sm-2 ProfilesPageSearch__input',
                                })}
                                type="text"
                                placeholder={windowWidth.width < 427 ? 'Search' : 'Find your legal team'}
                            />
                            {isOpen && (
                                <div {...getMenuProps({className: 'ProfilesPageSearch__content'})}>
                                    {!!profiles?.length &&
                                        profiles.map((profile, index) => {
                                            return (
                                                <div
                                                    className="ProfilesPageSearch__content__row p-2 "
                                                    {...getItemProps({
                                                        key: profile.id,
                                                        index,
                                                        item: profile,
                                                        style: {
                                                            backgroundColor:
                                                                highlightedIndex === index ? 'lightgray' : 'white',
                                                            fontWeight: selectedItem === profile ? 'bold' : 'normal',
                                                        },
                                                    })}
                                                >
                                                    <div>
                                                        {profile.image_url ? (
                                                            <img
                                                                alt={profile.firstname}
                                                                style={{
                                                                    height: '50px',
                                                                    width: '50px',
                                                                    borderRadius: '50%',
                                                                    objectFit: 'cover',
                                                                }}
                                                                src={profile.image_url}
                                                            />
                                                        ) : (
                                                            <ProfilePicture
                                                                imgClassName="article"
                                                                round
                                                                size="xs"
                                                                table
                                                            />
                                                        )}
                                                    </div>
                                                    <div>{profile.firstname + ' ' + profile.lastname}</div>
                                                </div>
                                            );
                                        })}
                                    {isOpen && hasSuccess && !profiles.length && !isLoading && (
                                        <Dropdown.Item className="text-center py-4">No Results</Dropdown.Item>
                                    )}
                                </div>
                            )}
                        </div>
                        {/* TODO: restore this when filter overlay is working */}
                        <div className="FilterIcon">
                            <Button variant="link" onClick={showModal}>
                                <img src="/assets/filterIcon.svg" alt="" />
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>
        </Row>
    );
};
